//ユーザーCSVアップロードページ
import React, { Fragment, useState } from 'react';
import { Typography, Button, Form, message, Input, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import Dropzone from 'react-dropzone';
import Axios from 'axios';
// import config from '../../Config/key';
import Papa from 'papaparse';
import Encoding from 'encoding-japanese';
// import { forEach } from 'core-js/core/array';
// const bcrypt = require('bcrypt');
// const saltRounds = 10;
const { Option } = Select;
const { TextArea } = Input;
const { Title, Text } = Typography;

function CSVUserCheckPage(props) {
  const [fileData, setFileData] = useState();
  const [fileJSON, setFileJSON] = useState();
  const [groups, setGroups] = useState([]); //グループ名とグループコード

  // const [projectDescriptionValue, setProjectDescriptionValue] = useState('');
  const [groupCode, setGroupCode] = useState('');
  const [submitButtonDisable, setSubmitButtonDisable] = useState(true);

  const onDrop = async (files) => {
    const file = files[0];
    const reader = new FileReader();
    reader.onabort = () => console.log('file reading was aborted');
    reader.onerror = () => console.log('file reading failed');
    reader.onload = (e) => {
      //ファイルの読み込みが終わったら発火
      const codes = new Uint8Array(e.target.result);
      const encoding = Encoding.detect(codes);
      const unicodeString = Encoding.convert(codes, {
        to: 'unicode',
        from: encoding,
        type: 'string',
      });
      Papa.parse(unicodeString, {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        complete: async (results) => {
          setFileData(unicodeString);
          setFileJSON(results.data);
          //const courseInfo = courseMerge(results.data);
          // setProjectKeyValue(`${courseInfo.course_id}_${ymdhms()}`);
          // console.log({ courseInfo });
        },
      });
    };
    reader.readAsArrayBuffer(file); //ファイルを読み込む
    const group = await Axios.get('/api/group/');
    setGroups(group.data.groups);
    // console.log(group.data.groups);
  };

  //yyyymmddhhmmss
  const ymdhms = () => {
    var date = new Date();
    return (
      date.getFullYear() +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      ('0' + date.getDate()).slice(-2) +
      ('0' + date.getHours()).slice(-2) +
      ('0' + date.getMinutes()).slice(-2) +
      ('0' + date.getSeconds()).slice(-2)
      // date.getMilliseconds()
    );
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    let userEmail = []; //最終分類用
    try {
      message.loading({
        content: 'メール確認中...',
        key: 'csvupload',
        duration: 20,
      });
      if (!groupCode) {
        message.error({
          content: '企業コードを入力してください。',
          key: 'csvupload',
          duration: 2,
        });
        throw new Error('企業コードがありません。');
      }

      await fileJSON.map((record) => {
        userEmail.push(record.email);
      });

      const gUsers = await Axios.get('/api/users/group/' + groupCode);

      if (gUsers.data.users.length > 0) {
        // console.log(gUsers.data.users);
        // console.log({ userEmail });
        let existEmail = [];
        let noEmail = [];
        // for (const gu of gUsers.data.users) {
        //   const gum = userEmail.find((item) => item === gu.email);
        //   if (gum) {
        //     existEmail.push(gum);
        //   } else {
        //     noEmail.push(gu.email);
        //   }
        // }
        for (const oneUser of userEmail) {
          const gum = gUsers.data.users.find((item) => item.email === oneUser);
          if (gum) {
            existEmail.push(gum.email);
          } else {
            noEmail.push(oneUser);
          }
        }

        // for (const newMail of userEmail) {
        //   console.log({ newMail });
        //   const gum = gUsers.data.users.find((item) => item.email === newMail).email
        //   if(gum);
        // }
        // console.log({ existEmail });
        if (existEmail.length > 0) {
          setFileData(
            `＜登録済みメール＞\n${existEmail.length}件\n${existEmail}\n\n<未登録メール＞\n${noEmail.length}件\n${noEmail}`
          );
        } else {
          setFileData(
            `登録済みのメールはありません。\n\n<未登録メール＞\n${noEmail.length}件\n${noEmail}`
          );
        }
        message.success({
          content: 'メール確認完了！',
          key: 'csvupload',
          duration: 2,
        });
        // console.log({ existEmail });
        // setTimeout(() => {
        //   // props.history.push('/');
        // }, 1000);
      } else {
        message.error({
          content: 'メール確認に失敗しました。',
          key: 'csvupload',
          duration: 2,
        });
        throw new Error('メール確認に失敗しました。');
      }
    } catch (error) {
      console.log({ error });
      message.error({
        content: 'メールチェックに失敗しました。',
        key: 'csvupload',
        duration: 2,
      });
    }
  };

  const onChangeGroup = (value) => {
    if (value) {
      setGroupCode(value);
      setSubmitButtonDisable(false);
    }
    // console.log(e);
  };

  return (
    <Fragment>
      <div style={{ maxWidth: '700px', margin: '2rem auto' }}>
        <div style={{ textAlign: 'center', marginBottom: '2rem' }}>
          <Title level={2}>受講者メールチェック</Title>
        </div>
        <Form onSubmit={onSubmit}>
          <br />
          <Title>❶</Title>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {/* Drop zone */}
            <Dropzone onDrop={onDrop} multiple={false} maxSize={1000000000}>
              {({ getRootProps, getInputProps }) => (
                <div
                  style={{
                    width: '300px',
                    height: '240px',
                    border: '1px solid lightgray',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <PlusOutlined style={{ fontSize: '3rem' }} />
                </div>
              )}
            </Dropzone>
            <div
              style={{
                width: '300px',
                height: '240px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Text mark>
                CSVファイルをDrag &
                Dropするか、+を押してファイルを選択してください。
              </Text>
            </div>
          </div>
          <br />
          <div>
            <Title>❷</Title>
            {/* <Text strong>企業コードを入力してください（必須）</Text>
            <Input
              placeholder="一意になる企業コードを入力してください。"
              allowClear
              onChange={onChangeGroupCode}
              value={groupCode}
            /> */}
            <Text strong>企業を選択してください。（必須）</Text>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Select
                showSearch
                style={{ marginLeft: 0, marginRight: 10, width: 300 }}
                placeholder="企業選択"
                optionFilterProp="items"
                onChange={onChangeGroup}
                value={groupCode}
                filterOption={(input, option) =>
                  option.props.items
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {groups.map((element) => {
                  // console.log({ element });
                  return (
                    <Option value={element.group_code} key={element._id}>
                      {element.group_name}
                    </Option>
                  );
                })}
              </Select>
            </div>
            <br />
          </div>
          <br />
          <br />
          <Title>❸</Title>
          <Button
            type="primary"
            size="large"
            onClick={onSubmit}
            disabled={submitButtonDisable}
          >
            メール確認
          </Button>
        </Form>
        <TextArea rows={20} value={fileData} />
      </div>
    </Fragment>
  );
}

export default CSVUserCheckPage;
