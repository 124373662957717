import React, { Suspense } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import Auth from '../hoc/auth';
// pages for this product
import LandingPage from './views/LandingPage/LandingPage.js';
import LoginPage from './views/LoginPage/LoginPage.js';
import RegisterPage from './views/RegisterPage/RegisterPage.js';
import ResetUserPage from './views/ResetUserPage/ResetUserPage.js';
import CPPage from './views/CPPage/CPPage.js';
import NavBar from './views/NavBar/NavBar';
import Footer from './views/Footer/Footer';
import UserMgrPage from './views/UserMgrPage/UserMgrPage';
import RespondentMgrPage from './views/UserMgrPage/RespondentMgrPage';
import E3DepMgrPage from './views/UserMgrPage/E3DepMgrPage.js';
import Status360Page from './views/UserMgrPage/Status360Page';
import CorpMgrPage from './views/UserMgrPage/CorpMgrPage';
import CSVUploadPage from './views/CSVUploadPage/CSVUploadPage';
import CSVUserUploadPage from './views/CSVUserUploadPage/CSVUserUploadPage';
import ChatGptTestPage from './views/ChatGptPage/ChatGptTestPage';
import CSVUserUpdateOnlyPage from './views/CSVUserUploadPage/CSVUserUpdateOnlyPage';
import CSVUserUpdatePage from './views/CSVUserUploadPage/CSVUserUpdatePage';
import CSVUserCheckPage from './views/CSVUserUploadPage/CSVUserCheckPage';
import CSVRelationUploadPage from './views/CSVUserUploadPage/CSVRelationUploadPage';
import CSVTokenInsertPage from './views/CSVUserUploadPage/CSVTokenInsertPage';
import CSVDownloadPage from './views/CSVDownloadPage/CSVDownloadPage';
import MakeReportPage from './views/MakeReportPage/MakeReportPage';
import ReportListPage from './views/ReportListPage/ReportListPage';
import TakeSurveyPage from './views/TakeSurveyPage/TakeSurveyPage';
import NewTakeSurveyPage from './views/TakeSurveyPage/NewTakeSurveyPage';
import Course90101Page from './views/TakeSurveyPage/Course90101Page';
import Course90105Page from './views/TakeSurveyPage/Course90105Page'; //人事評価力診断の新動画・新フォーマット対応20250401以降
import Course90301Page from './views/TakeSurveyPage/Course90301Page';
import Course90501Page from './views/TakeSurveyPage/Course90501Page';
import MySurveyPage from './views/MySurveyPage/MySurveyPage';
import QuestionMgrPage from './views/QuestionMgrPage/QuestionMgrPage';
import QuestionSetMgrPage from './views/QuestionSetMgrPage/QuestionSetMgrPage';
import PDF10010DownloadPage from './views/PDFDownloadPage/PDF10010DownloadPage';
import PDF10010CompanyDownloadPage from './views/PDFDownloadPage/PDF10010CompanyDownloadPage';
import PDF20110DownloadPage from './views/PDFDownloadPage/PDF20110DownloadPage';
import PDF30010DownloadPage from './views/PDFDownloadPage/PDF30010DownloadPage';
import PDF50010DownloadPage from './views/PDFDownloadPage/PDF50010DownloadPage';
import PDF60010DownloadPage from './views/PDFDownloadPage/PDF60010DownloadPage';
import PDF70010DownloadPage from './views/PDFDownloadPage/PDF70010DownloadPage';
import PDF80010DownloadPage from './views/PDFDownloadPage/PDF80010DownloadPage';
import Excel30010DownloadPage from './views/PDFDownloadPage/Excel30010DownloadPage';
import Excel30010CompareDownloadPage from './views/PDFDownloadPage/Excel30010CompareDownloadPage';
import CompanySum30010Page from './views/PDFDownloadPage/CompanySum30010Page';
import MailTemplatePage from './views/SendMailPage/MailTemplatePage';
import MakeTemplatePage from './views/SendMailPage/MakeTemplatePage';
import CSVMailSendPage from './views/SendMailPage/CSVMailSendPage';
import SurveyMailSendPage from './views/SendMailPage/SurveyMailSendPage';
import RemindMailSendPage from './views/SendMailPage/RemindMailSendPage';
import GroupListPage from './views/DataMgrPage/GroupListPage';
import MakeGroupPage from './views/DataMgrPage/MakeGroupPage';
import SeasonListPage from './views/DataMgrPage/SeasonListPage';
import MakeSeasonPage from './views/DataMgrPage/MakeSeasonPage';
import MakeQSetPage from './views/DataMgrPage/MakeQSetPage';
import AssignQSetPage from './views/DataMgrPage/AssignQSetPage';
import VideoUploadPage from './views/VideoPage/VideoUploadPage';
import VideoEditPage from './views/VideoPage/VideoEditPage';
import VideoMgrPage from './views/VideoPage/VideoMgrPage';
import VideoDeliveryMgrPage from './views/VideoPage/VideoDeliveryMgrPage';
import VideoDeliveryPage from './views/VideoPage/VideoDeliveryPage';
import VideoViewPage from './views/VideoPage/VideoList/VideoViewPage';

//null   Anyone Can go inside
//true   only logged in user can go inside
//false  logged in user can't go inside

function App() {
  const location = useLocation();
  // console.log(location.pathname);
  return (
    <Suspense fallback={<div>Loading...</div>}>
      {location.pathname.match('/videolist/video') ? '' : <NavBar />}
      <div style={{ paddingTop: '69px', minHeight: 'calc(100vh - 80px)' }}>
        <Switch>
          <Route exact path="/" component={Auth(LandingPage, null)} />
          <Route exact path="/login" component={Auth(LoginPage, false)} />
          <Route exact path="/register" component={Auth(RegisterPage, false)} />
          <Route
            exact
            path="/resetuser"
            component={Auth(ResetUserPage, false)}
          />
          <Route
            exact
            path="/videolist/videoview/:t"
            component={VideoViewPage}
          />
          <Route exact path="/cp" component={Auth(CPPage, true)} />
          <Route exact path="/usermgr" component={Auth(UserMgrPage, true)} />
          <Route
            exact
            path="/respondentmgr"
            component={Auth(RespondentMgrPage, true)}
          />
          <Route exact path="/e3depmgr" component={Auth(E3DepMgrPage, true)} />
          <Route
            exact
            path="/status360"
            component={Auth(Status360Page, true)}
          />
          <Route
            exact
            path="/csvuserupload"
            component={Auth(CSVUserUploadPage, true)}
          />
          <Route
            exact
            path="/csvusercheck"
            component={Auth(CSVUserCheckPage, true)}
          />
          <Route
            exact
            path="/csvuserupdate"
            component={Auth(CSVUserUpdatePage, true)}
          />
          <Route
            exact
            path="/csvuserupdateonly"
            component={Auth(CSVUserUpdateOnlyPage, true)}
          />
          <Route
            exact
            path="/csvrelationupload"
            component={Auth(CSVRelationUploadPage, true)}
          />
          <Route
            exact
            path="/csvtokeninsert"
            component={Auth(CSVTokenInsertPage, true)}
          />
          <Route
            exact
            path="/csvdownload"
            component={Auth(CSVDownloadPage, true)}
          />
          <Route
            exact
            path="/download10010"
            component={Auth(PDF10010DownloadPage, true)}
          />
          <Route
            exact
            path="/download10010_company"
            component={Auth(PDF10010CompanyDownloadPage, true)}
          />
          <Route
            exact
            path="/download50010"
            component={Auth(PDF50010DownloadPage, true)}
          />
          <Route
            exact
            path="/download60010"
            component={Auth(PDF60010DownloadPage, true)}
          />
          <Route
            exact
            path="/download20110"
            component={Auth(PDF20110DownloadPage, true)}
          />
          <Route
            exact
            path="/download30010"
            component={Auth(PDF30010DownloadPage, true)}
          />
          <Route
            exact
            path="/download70010"
            component={Auth(PDF70010DownloadPage, true)}
          />
          <Route
            exact
            path="/download80010"
            component={Auth(PDF80010DownloadPage, true)}
          />
          <Route
            exact
            path="/exceldownload30010"
            component={Auth(Excel30010DownloadPage, true)}
          />
          <Route
            exact
            path="/excelcomparedownload30010"
            component={Auth(Excel30010CompareDownloadPage, true)}
          />
          <Route
            exact
            path="/companysum30010"
            component={Auth(CompanySum30010Page, true)}
          />
          <Route exact path="/corpmgr" component={Auth(CorpMgrPage, true)} />
          <Route exact path="/mysurvey" component={Auth(MySurveyPage, true)} />
          <Route
            exact
            path="/takesurvey/:tokenId"
            component={Auth(TakeSurveyPage, true)}
          />
          <Route
            exact
            path="/newtakesurvey"
            component={Auth(NewTakeSurveyPage, true)}
          />
          <Route
            exact
            path="/course90101"
            component={Auth(Course90101Page, true)}
          />
          <Route
            exact
            path="/course90105"
            component={Auth(Course90105Page, true)}
          />
          <Route
            exact
            path="/course90301"
            component={Auth(Course90301Page, true)}
          />
          <Route
            exact
            path="/course90501"
            component={Auth(Course90501Page, true)}
          />
          <Route
            exact
            path="/csvupload"
            component={Auth(CSVUploadPage, true)}
          />
          <Route
            exact
            path="/makereport"
            component={Auth(MakeReportPage, true)}
          />
          <Route
            exact
            path="/reportlist/:projectId"
            component={Auth(ReportListPage, true)}
          />
          <Route
            exact
            path="/questionmgr"
            component={Auth(QuestionMgrPage, true)}
          />
          <Route
            exact
            path="/questionsetmgr"
            component={Auth(QuestionSetMgrPage, true)}
          />
          <Route
            exact
            path="/csvmailsend"
            component={Auth(CSVMailSendPage, true)}
          />
          <Route
            exact
            path="/surveymailsend"
            component={Auth(SurveyMailSendPage, true)}
          />
          <Route
            exact
            path="/remindmailsend"
            component={Auth(RemindMailSendPage, true)}
          />
          <Route
            exact
            path="/mailtemplate"
            component={Auth(MailTemplatePage, true)}
          />
          <Route
            exact
            path="/maketemplate"
            component={Auth(MakeTemplatePage, true)}
          />
          <Route
            exact
            path="/grouplist"
            component={Auth(GroupListPage, true)}
          />
          <Route
            exact
            path="/makegroup"
            component={Auth(MakeGroupPage, true)}
          />
          <Route
            exact
            path="/makeseason"
            component={Auth(MakeSeasonPage, true)}
          />
          <Route
            exact
            path="/seasonlist"
            component={Auth(SeasonListPage, true)}
          />
          <Route exact path="/makeqset" component={Auth(MakeQSetPage, true)} />
          <Route
            exact
            path="/assignqset"
            component={Auth(AssignQSetPage, true)}
          />
          <Route
            exact
            path="/videoupload"
            component={Auth(VideoUploadPage, true)}
          />
          <Route
            exact
            path="/videoedit"
            component={Auth(VideoEditPage, true)}
          />
          <Route exact path="/videomgr" component={Auth(VideoMgrPage, true)} />
          <Route
            exact
            path="/videodelivery"
            component={Auth(VideoDeliveryPage, true)}
          />
          <Route
            exact
            path="/videodeliverymgr"
            component={Auth(VideoDeliveryMgrPage, true)}
          />
          <Route
            exact
            path="/chatgpttest"
            component={Auth(ChatGptTestPage, true)}
          />
        </Switch>
      </div>
      <Footer />
    </Suspense>
  );
}

export default App;
