//人事評価力診断の新動画・新フォーマット対応20250401以降
import Axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react';
// import Vimeo from '@vimeo/vimeo';
import {
  Card,
  Col,
  Spin,
  Row,
  Tooltip,
  Modal,
  message,
  Typography,
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import config from '../../Config/key';
import Vimeo from '@u-wave/react-vimeo';
const { Title, Text } = Typography;
const { confirm } = Modal;
// var Vimeo = require('vimeo').Vimeo;
function Course90105Page(props) {
  const [viewModal, setViewModal] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [paused, setPaused] = useState(false);
  const [videoId, setVideoId] = useState('');
  const thumbnailURL = `${config.S3_URL}/${config.S3_ENV}/videos/fixedThumbnail/`;
  let sendData = props.location.state;
  const { userData } = props.user;
  let tokenInfo = props.location.state.tokenInfo;
  // console.log({ props });
  let surveyStatus = sendData.tokenInfo.survey_status === 5 ? true : false;

  useEffect(() => {}, []);
  //ModalでのOK
  const handleOk = (e) => {
    // console.log({ paused });
    setPaused(true);
    setTimeout(() => {
      setViewModal(false);
    }, 200);
  };

  const handleCancel = (e) => {
    // console.log({ paused });
    setPaused(true);
    setTimeout(() => {
      setViewModal(false);
    }, 200);
  };
  const handleOnPause = (e) => {
    console.log({ e });
    // setPaused(true);
    setViewModal(false);
  };

  //ModalでのOK
  const handleCardClick = (v) => {
    // console.log(v);
    if (v === 'takeSurvey') {
      sendData.tokenInfo.survey_code = '10010';
      // console.log({ sendData });
      props.history.push({
        pathname: '/newtakesurvey',
        state: sendData,
      });
    } else {
      setVideoId(v);
      setPaused(false);
      setViewModal(true);
    }
  };

  const showConfirm = async () => {
    confirm({
      title: 'サーベイ結果を生成しますか？',
      content:
        'サーベイ結果を生成し別TabでPDF表示します。このままお待ちください。',
      async onOk() {
        setIsModalVisible(true);
        let ti = tokenInfo;
        ti.group_name = userData.company;
        ti.company = userData.company;
        ti.survey_end_date = tokenInfo.updateAt;
        ti.survey_name = tokenInfo.surveyName;
        ti.emp_no = userData.empNo;
        ti.department = userData.department;
        ti.user_name = userData.lastname + ' ' + userData.name;
        let result = await Axios.post('/api/dbModel100/makeReport', ti);
        // console.log({ result });
        if (result.data.success) {
          // message.success('結果生成が完了しました。マイ・サーベイに戻ります。');
          // props.history.push('/mysurvey');
          const url = `${config.S3_URL}/${result.data.s3Path}`;
          // console.log({ url });
          setTimeout(() => {
            message.success({
              content: 'ファイル生成完了！',
              key: 'makecsv',
              duration: 2,
            });
            setIsModalVisible(false);
            window.open(url, '_blank');
          }, 3000);
        } else {
          setIsModalVisible(false);
          console.log('結果生成で問題が発生しました。');
          message.error('結果生成で問題が発生しました。');
        }
      },
      async onCancel() {},
    });
  };

  return (
    <Fragment>
      <div style={{ width: '90%', margin: '1rem auto' }}>
        <div style={{ textAlign: 'center', marginBottom: '2rem' }}>
          <Title level={2}>評価者育成プログラム</Title>
        </div>
        {sendData.tokenInfo.coursePartView[0] ? (
          <Card
            title="人事評価の基本"
            bodyStyle={{ backgroundColor: '#EEEEEE' }}
            headStyle={{ backgroundColor: '#5c6cfa', color: '#ffffff' }}
            extra={
              <Tooltip
                placement="bottomRight"
                title={
                  'ここでは、人事評価の基本的な考え方や運用について学習します。\n【学習時間目安：10分】'
                }
                color={'orange'}
                key={'orange'}
              >
                <QuestionCircleOutlined
                  style={{ fontSize: '25px', color: '#FFF' }}
                />
              </Tooltip>
            }
          >
            <Row gutter={16}>
              <Col span={6}>
                <Card
                  hoverable
                  cover={
                    <div style={{ position: 'relative' }}>
                      <img
                        style={{ width: '100%' }}
                        alt="人事評価の目的"
                        src={thumbnailURL + '7.png'}
                      />
                      <div className="duration">
                        <span>
                          {('00' + 4).slice(-2)} : {('00' + 28).slice(-2)}
                        </span>
                      </div>
                    </div>
                  }
                  onClick={() => handleCardClick('799314299')}
                >
                  人事評価の目的
                </Card>
              </Col>
              <Col span={6}>
                <Card
                  hoverable
                  cover={
                    <div style={{ position: 'relative' }}>
                      <img
                        style={{ width: '100%' }}
                        alt="人事評価制度の基本的な知識"
                        src={thumbnailURL + '7.png'}
                      />
                      <div className="duration">
                        <span>
                          {('00' + 5).slice(-2)} : {('00' + 8).slice(-2)}
                        </span>
                      </div>
                    </div>
                  }
                  onClick={() => handleCardClick('799314894')}
                >
                  人事評価制度の基本的な知識
                </Card>
              </Col>
            </Row>
          </Card>
        ) : (
          <></>
        )}
        <br />
        {sendData.tokenInfo.coursePartView[1] ? (
          <Card
            title="人事評価力診断"
            bodyStyle={{ backgroundColor: '#EEEEEE' }}
            headStyle={{ backgroundColor: '#5c6cfa', color: '#ffffff' }}
            extra={
              <Tooltip
                placement="bottomRight"
                title={
                  'ここでは、人事評価力診断の受検方法や結果の見方について学習します。\n【学習時間の目安：10分】'
                }
                color={'orange'}
                key={'orange'}
              >
                <QuestionCircleOutlined
                  style={{ fontSize: '25px', color: '#FFF' }}
                />
              </Tooltip>
            }
          >
            <Row gutter={16}>
              <Col span={6}>
                <Card
                  hoverable
                  cover={
                    <div style={{ position: 'relative' }}>
                      <img
                        style={{ width: '100%' }}
                        alt="診断概要紹介"
                        src={thumbnailURL + '19.png'}
                      />
                      <div className="duration">
                        <span>
                          {('00' + 1).slice(-2)} : {('00' + 39).slice(-2)}
                        </span>
                      </div>
                    </div>
                  }
                  onClick={() => handleCardClick('1055859666')}
                >
                  診断概要紹介
                </Card>
              </Col>
              <Col span={6}>
                {surveyStatus ? (
                  <Card
                    hoverable={false}
                    cover={
                      <img alt="診断受検完了" src={thumbnailURL + 'd_5.png'} />
                    }
                  >
                    診断受検完了
                  </Card>
                ) : (
                  <Card
                    hoverable={true}
                    cover={
                      <img
                        alt="診断受検はこちら"
                        src={thumbnailURL + '5.png'}
                        onClick={() => handleCardClick('takeSurvey')}
                      />
                    }
                  >
                    診断受検はこちら
                  </Card>
                )}
              </Col>
              <Col span={6}>
                {surveyStatus ? (
                  <Card
                    hoverable={true}
                    cover={
                      <img
                        alt="診断結果PDFダウンロード"
                        src={thumbnailURL + '4.png'}
                        onClick={showConfirm}
                      />
                    }
                  >
                    診断結果PDFダウンロード
                  </Card>
                ) : (
                  <Card
                    hoverable={false}
                    cover={
                      <img
                        alt="診断結果PDFダウンロード"
                        src={thumbnailURL + 'd_4.png'}
                      />
                    }
                  >
                    診断結果PDFダウンロード
                  </Card>
                )}
              </Col>
              <Col span={6}>
                {surveyStatus ? (
                  <Card
                    hoverable
                    cover={
                      <div style={{ position: 'relative' }}>
                        <img
                          style={{ width: '100%' }}
                          alt="診断結果の読み方①"
                          src={thumbnailURL + '20.png'}
                        />
                        <div className="duration">
                          <span>
                            {('00' + 3).slice(-2)} : {('00' + 43).slice(-2)}
                          </span>
                        </div>
                      </div>
                    }
                    onClick={() => handleCardClick('1055860029')}
                  >
                    診断結果の読み方①
                  </Card>
                ) : (
                  <Card
                    hoverable={false}
                    cover={
                      <div style={{ position: 'relative' }}>
                        <img
                          style={{ width: '100%' }}
                          alt="診断結果の読み方①"
                          src={thumbnailURL + 'd_20.png'}
                        />
                      </div>
                    }
                  >
                    診断結果の読み方①
                  </Card>
                )}
              </Col>
              <Col span={6}>
                {surveyStatus ? (
                  <Card
                    style={{ marginTop: 20 }}
                    hoverable
                    cover={
                      <div style={{ position: 'relative' }}>
                        <img
                          style={{ width: '100%' }}
                          alt="診断結果の読み方②"
                          src={thumbnailURL + '20.png'}
                        />
                        <div className="duration">
                          <span>
                            {('00' + 11).slice(-2)} : {('00' + 11).slice(-2)}
                          </span>
                        </div>
                      </div>
                    }
                    onClick={() => handleCardClick('1055860357')}
                  >
                    診断結果の読み方②
                  </Card>
                ) : (
                  <Card
                    style={{ marginTop: 20 }}
                    hoverable={false}
                    cover={
                      <div style={{ position: 'relative' }}>
                        <img
                          style={{ width: '100%' }}
                          alt="診断結果の読み方②"
                          src={thumbnailURL + 'd_20.png'}
                        />
                      </div>
                    }
                  >
                    診断結果の読み方②
                  </Card>
                )}
              </Col>
            </Row>
          </Card>
        ) : (
          <></>
        )}
        <br />
        {sendData.tokenInfo.coursePartView[2] ? (
          <Card
            title="評価エラー傾向の解説"
            headStyle={{ backgroundColor: '#5c6cfa', color: '#ffffff' }}
            bodyStyle={{ backgroundColor: '#EEEEEE' }}
            extra={
              <Tooltip
                placement="bottomRight"
                title={
                  'ここでは、人事評価における6つのエラー傾向について学習します。\n【学習時間の目安：35分】'
                }
                color={'orange'}
                key={'orange'}
              >
                <QuestionCircleOutlined
                  style={{ fontSize: '25px', color: '#FFF' }}
                />
              </Tooltip>
            }
          >
            <Row gutter={16}>
              <Col span={6}>
                {surveyStatus ? (
                  <Card
                    hoverable
                    cover={
                      <div style={{ position: 'relative' }}>
                        <img
                          style={{ width: '100%' }}
                          alt="ハロー効果"
                          src={thumbnailURL + '9.png'}
                        />
                        <div className="duration">
                          <span>
                            {('00' + 5).slice(-2)} : {('00' + 17).slice(-2)}
                          </span>
                        </div>
                      </div>
                    }
                    onClick={() => handleCardClick('1055860823')}
                  >
                    ハロー効果
                  </Card>
                ) : (
                  <Card
                    hoverable={false}
                    cover={
                      <div style={{ position: 'relative' }}>
                        <img
                          style={{ width: '100%' }}
                          alt="ハロー効果"
                          src={thumbnailURL + 'd_9.png'}
                        />
                      </div>
                    }
                  >
                    ハロー効果
                  </Card>
                )}
              </Col>
              <Col span={6}>
                {surveyStatus ? (
                  <Card
                    hoverable
                    cover={
                      <div style={{ position: 'relative' }}>
                        <img
                          style={{ width: '100%' }}
                          alt="寛大化傾向"
                          src={thumbnailURL + '10.png'}
                        />
                        <div className="duration">
                          <span>
                            {('00' + 6).slice(-2)} : {('00' + 16).slice(-2)}
                          </span>
                        </div>
                      </div>
                    }
                    onClick={() => handleCardClick('1055861570')}
                  >
                    寛大化傾向
                  </Card>
                ) : (
                  <Card
                    hoverable={false}
                    cover={
                      <div style={{ position: 'relative' }}>
                        <img
                          style={{ width: '100%' }}
                          alt="寛大化傾向"
                          src={thumbnailURL + 'd_10.png'}
                        />
                      </div>
                    }
                  >
                    寛大化傾向
                  </Card>
                )}
              </Col>
              <Col span={6}>
                {surveyStatus ? (
                  <Card
                    hoverable
                    cover={
                      <div style={{ position: 'relative' }}>
                        <img
                          style={{ width: '100%' }}
                          alt="逆算化傾向"
                          src={thumbnailURL + '11.png'}
                        />
                        <div className="duration">
                          <span>
                            {('00' + 5).slice(-2)} : {('00' + 10).slice(-2)}
                          </span>
                        </div>
                      </div>
                    }
                    onClick={() => handleCardClick('1055862126')}
                  >
                    逆算化傾向
                  </Card>
                ) : (
                  <Card
                    hoverable={false}
                    cover={
                      <div style={{ position: 'relative' }}>
                        <img
                          style={{ width: '100%' }}
                          alt="逆算化傾向"
                          src={thumbnailURL + 'd_11.png'}
                        />
                      </div>
                    }
                  >
                    逆算化傾向
                  </Card>
                )}
              </Col>
              <Col span={6}>
                {surveyStatus ? (
                  <Card
                    hoverable
                    cover={
                      <div style={{ position: 'relative' }}>
                        <img
                          style={{ width: '100%' }}
                          alt="対比誤差"
                          src={thumbnailURL + '12.png'}
                        />
                        <div className="duration">
                          <span>
                            {('00' + 6).slice(-2)} : {('00' + 27).slice(-2)}
                          </span>
                        </div>
                      </div>
                    }
                    onClick={() => handleCardClick('1055862513')}
                  >
                    対比誤差
                  </Card>
                ) : (
                  <Card
                    hoverable={false}
                    cover={
                      <div style={{ position: 'relative' }}>
                        <img
                          style={{ width: '100%' }}
                          alt="対比誤差"
                          src={thumbnailURL + 'd_12.png'}
                        />
                      </div>
                    }
                  >
                    対比誤差
                  </Card>
                )}
              </Col>
              <Col span={6}>
                {surveyStatus ? (
                  <Card
                    style={{ marginTop: 20 }}
                    hoverable
                    cover={
                      <div style={{ position: 'relative' }}>
                        <img
                          style={{ width: '100%' }}
                          alt="中心化傾向"
                          src={thumbnailURL + '13.png'}
                        />
                        <div className="duration">
                          <span>
                            {('00' + 7).slice(-2)} : {('00' + 49).slice(-2)}
                          </span>
                        </div>
                      </div>
                    }
                    onClick={() => handleCardClick('1055862762')}
                  >
                    中心化傾向
                  </Card>
                ) : (
                  <Card
                    style={{ marginTop: 20 }}
                    hoverable={false}
                    cover={
                      <div style={{ position: 'relative' }}>
                        <img
                          style={{ width: '100%' }}
                          alt="中心化傾向"
                          src={thumbnailURL + 'd_13.png'}
                        />
                      </div>
                    }
                  >
                    中心化傾向
                  </Card>
                )}
              </Col>
              <Col span={6}>
                {surveyStatus ? (
                  <Card
                    style={{ marginTop: 20 }}
                    hoverable
                    cover={
                      <div style={{ position: 'relative' }}>
                        <img
                          style={{ width: '100%' }}
                          alt="論理誤差"
                          src={thumbnailURL + '14.png'}
                        />
                        <div className="duration">
                          <span>
                            {('00' + 7).slice(-2)} : {('00' + 17).slice(-2)}
                          </span>
                        </div>
                      </div>
                    }
                    onClick={() => handleCardClick('1055863121')}
                  >
                    論理誤差
                  </Card>
                ) : (
                  <Card
                    style={{ marginTop: 20 }}
                    hoverable={false}
                    cover={
                      <div style={{ position: 'relative' }}>
                        <img
                          style={{ width: '100%' }}
                          alt="論理誤差"
                          src={thumbnailURL + 'd_14.png'}
                        />
                      </div>
                    }
                  >
                    論理誤差
                  </Card>
                )}
              </Col>
            </Row>
          </Card>
        ) : (
          <></>
        )}
        <br />
        {sendData.tokenInfo.coursePartView[3] ? (
          <Card
            title="目標設定面談"
            bodyStyle={{ backgroundColor: '#EEEEEE' }}
            headStyle={{ backgroundColor: '#5c6cfa', color: '#ffffff' }}
            extra={
              <Tooltip
                placement="bottomRight"
                title={
                  'ここでは、目標設定面談の目的やその進め方について学習します。【学習時間の目安：30分】'
                }
                color={'orange'}
                key={'orange'}
              >
                <QuestionCircleOutlined
                  style={{ fontSize: '25px', color: '#FFF' }}
                />
              </Tooltip>
            }
          >
            <Row gutter={16}>
              <Col span={6}>
                {surveyStatus ? (
                  <Card
                    hoverable
                    cover={
                      <div style={{ position: 'relative' }}>
                        <img
                          style={{ width: '100%' }}
                          alt="目標設定面談"
                          src={thumbnailURL + '15.png'}
                        />
                        <div className="duration">
                          <span>
                            {('00' + 7).slice(-2)} : {('00' + 27).slice(-2)}
                          </span>
                        </div>
                      </div>
                    }
                    onClick={() => handleCardClick('799369289')}
                  >
                    目標設定面談
                  </Card>
                ) : (
                  <Card
                    hoverable={false}
                    cover={
                      <div style={{ position: 'relative' }}>
                        <img
                          style={{ width: '100%' }}
                          alt="目標設定面談"
                          src={thumbnailURL + 'd_15.png'}
                        />
                      </div>
                    }
                  >
                    目標設定面談
                  </Card>
                )}
              </Col>
              <Col span={6}>
                {surveyStatus ? (
                  <Card
                    hoverable
                    cover={
                      <div style={{ position: 'relative' }}>
                        <img
                          style={{ width: '100%' }}
                          alt="中間面談"
                          src={thumbnailURL + '16.png'}
                        />
                        <div className="duration">
                          <span>
                            {('00' + 10).slice(-2)} : {('00' + 19).slice(-2)}
                          </span>
                        </div>
                      </div>
                    }
                    onClick={() => handleCardClick('799369934')}
                  >
                    中間面談
                  </Card>
                ) : (
                  <Card
                    hoverable={false}
                    cover={
                      <div style={{ position: 'relative' }}>
                        <img
                          style={{ width: '100%' }}
                          alt="中間面談"
                          src={thumbnailURL + 'd_16.png'}
                        />
                      </div>
                    }
                  >
                    中間面談
                  </Card>
                )}
              </Col>
              <Col span={6}>
                {surveyStatus ? (
                  <Card
                    hoverable
                    cover={
                      <div style={{ position: 'relative' }}>
                        <img
                          style={{ width: '100%' }}
                          alt="評価面談①"
                          src={thumbnailURL + '17.png'}
                        />
                        <div className="duration">
                          <span>
                            {('00' + 8).slice(-2)} : {('00' + 7).slice(-2)}
                          </span>
                        </div>
                      </div>
                    }
                    onClick={() => handleCardClick('799370335')}
                  >
                    評価面談①
                  </Card>
                ) : (
                  <Card
                    hoverable={false}
                    cover={
                      <div style={{ position: 'relative' }}>
                        <img
                          style={{ width: '100%' }}
                          alt="評価面談①"
                          src={thumbnailURL + 'd_17.png'}
                        />
                      </div>
                    }
                  >
                    評価面談①
                  </Card>
                )}
              </Col>
              <Col span={6}>
                {surveyStatus ? (
                  <Card
                    hoverable
                    cover={
                      <div style={{ position: 'relative' }}>
                        <img
                          style={{ width: '100%' }}
                          alt="評価面談②"
                          src={thumbnailURL + '18.png'}
                        />
                        <div className="duration">
                          <span>
                            {('00' + 8).slice(-2)} : {('00' + 33).slice(-2)}
                          </span>
                        </div>
                      </div>
                    }
                    onClick={() => handleCardClick('799370686')}
                  >
                    評価面談②
                  </Card>
                ) : (
                  <Card
                    hoverable={false}
                    cover={
                      <div style={{ position: 'relative' }}>
                        <img
                          style={{ width: '100%' }}
                          alt="評価面談②"
                          src={thumbnailURL + 'd_18.png'}
                        />
                      </div>
                    }
                  >
                    評価面談②
                  </Card>
                )}
              </Col>
            </Row>
          </Card>
        ) : (
          <></>
        )}
        <br />
        {/* {sendData.tokenInfo.coursePartView[4] ? (
          <Card
            title="資料DL / 確認テスト"
            bodyStyle={{ backgroundColor: '#EEEEEE' }}
            headStyle={{ backgroundColor: '#5c6cfa', color: '#ffffff' }}
            extra={
              <Tooltip
                placement="bottomRight"
                title={'参考資料のダウンロードができます。'}
                color={'orange'}
                key={'orange'}
              >
                <QuestionCircleOutlined
                  style={{ fontSize: '25px', color: '#FFF' }}
                />
              </Tooltip>
            }
          >
            <Row gutter={16}>
              <Col span={6}>
                {surveyStatus ? (
                  <Card
                    hoverable
                    cover={
                      <img
                        alt="評価ハンドブック"
                        src={thumbnailURL + '1.png'}
                      />
                    }
                    bordered={true}
                  >
                    評価ハンドブック
                  </Card>
                ) : (
                  <Card
                    hoverable={false}
                    cover={
                      <img
                        alt="評価ハンドブック"
                        src={thumbnailURL + 'd_1.png'}
                      />
                    }
                    bordered={true}
                  >
                    評価ハンドブック
                  </Card>
                )}
              </Col>
              <Col span={6}>
                {surveyStatus ? (
                  <Card
                    hoverable
                    cover={<img alt="問題集" src={thumbnailURL + '2.png'} />}
                    bordered={true}
                  >
                    問題集
                  </Card>
                ) : (
                  <Card
                    hoverable={false}
                    cover={<img alt="問題集" src={thumbnailURL + 'd_2.png'} />}
                    bordered={true}
                  >
                    問題集
                  </Card>
                )}
              </Col>
              <Col span={6}>
                {surveyStatus ? (
                  <Card
                    hoverable
                    cover={<img alt="解説資料" src={thumbnailURL + '3.png'} />}
                    bordered={true}
                  >
                    解説資料
                  </Card>
                ) : (
                  <Card
                    hoverable={false}
                    cover={
                      <img alt="解説資料" src={thumbnailURL + 'd_3.png'} />
                    }
                    bordered={true}
                  >
                    解説資料
                  </Card>
                )}
              </Col>
            </Row>
          </Card>
        ) : (
          <></>
        )} */}
      </div>
      <div>
        <Modal
          title="動画再生"
          open={viewModal}
          onOk={handleOk}
          onCancel={handleCancel}
          bodyStyle={{ overflowX: 'scroll' }}
          width={1000}
          footer={null}
        >
          <Vimeo
            video={videoId}
            className="vimeo pc"
            responsive
            autoplay={true}
            speed={true}
            paused={paused}
          />
        </Modal>
      </div>
      <Modal
        title="サーベイ結果ファイル生成中..."
        open={isModalVisible}
        okButtonProps={{ disabled: true }}
        cancelButtonProps={{ disabled: true }}
        bodyStyle={{ textAlign: 'center' }}
      >
        <Spin size="large" />
      </Modal>
    </Fragment>
  );
}

export default Course90105Page;
